import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from 'src/app/features/auth/services/auth.service';

@Component({
  templateUrl: './resendmodal.component.html',
})
export class ResendmodalComponent implements OnInit {
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResendmodalComponent>,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
  }

  onResendMail() {
    const modal = {
      email: this.data.user_email,
    };
    this.loading = true;
    this.authService.resendVerification(modal).subscribe(
      (res) => {
        this.showSuccess(res.status);
        this.loading = false;
      },
      (err) => {
        this.showError(err.message);
        this.loading = false;
      }
    );
  }

  onClose() {
    this.dialogRef.close();
    this.router.navigate(['/login']);
  }

  showError(msg) {
    this.toastr.error(msg);
  }

  showSuccess(msg) {
    this.toastr.success(msg);
  }
}
