<div class="mat-modal__head" mat-dialog-title>
  <div class="modal-header">
    <h5 *ngIf="!Ddata.editMode" class="modal-title">
      Create Group Training Request
    </h5>
    <h5 *ngIf="Ddata.editMode" class="modal-title">
      Update Group Training Request
    </h5>
    <button aria-label="Close" class="close" mat-dialog-close type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-modal__content">
  <div class="modal-body">
    <form [formGroup]="formGroup">
      <!-- title -->
      <div class="fields-group mb-0">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="form-group">
              <mat-label> Training Title</mat-label>
              <input formControlName="training_name" matInput maxlength="200" />
              <mat-error *ngIf="hasError(formGroup, 'training_name', 'required')">
                Training Title Is Required
              </mat-error>
              <mat-error *ngIf="hasError(formGroup, 'training_name', 'maxlength')">
                The entered value exceed the maximum length of 200
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mb-4">
        <div class="attendees-title">
          <span class="create_content-document"> Attendees </span>
          <span>(Maximum 25)</span>
        </div>
        <!-- <button class="newTrainingbtn" mat-raised-button (click)="addAttendees()" [disabled]="Attendees.controls.length == 25">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path id="plus" d="M20.5,11.167H13.833V4.5H11.167v6.667H4.5v2.667h6.667V20.5h2.667V13.833H20.5Z" transform="translate(-4.5 -4.5)" fill="#e48d1a"/>
          </svg>
          <span> Add Attendee  </span>
        </button> -->
        <button (click)="addAttendees()" [disabled]="
            Attendees.controls.length == 25 ||
            !formGroup.controls.Attendees.valid ||
            Loading
          " class="btn-create" color="primary" mat-raised-button>
          <svg height="10" viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
            <g id="add-icon" transform="translate(-1166 -634)">
              <path d="M14.5,8.667H10.333V4.5H8.667V8.667H4.5v1.667H8.667V14.5h1.667V10.333H14.5Z" fill="#fff" id="plus"
                transform="translate(1161.5 629.5)" />
            </g>
          </svg>
          <span> Add Attendee </span>
        </button>
      </div>
      <div class="group-training">
        <ng-container formArrayName="Attendees">
          <ng-container *ngFor="let item of Attendees.controls; let i = index">
            <ng-container [formGroupName]="i">
              <!-- attendee -->
              <div [class.activeItem]="ActiveCollaped[i]" class="collaped-items">
                <div class="one-item">
                  <div (click)="ActiveCollaped[i] = !ActiveCollaped[i]" class="head">
                    <div>
                      <span> Attendee {{ i + 1 }}: </span>
                      <span>
                        {{ item.controls.first_name.value + ' ' }}
                        {{ item.controls.father_name.value + ' ' }}
                        {{ item.controls.grandfather_name.value + ' ' }}
                        {{ item.controls.family_name.value + ' ' }}
                      </span>
                    </div>
                    <img class="expand" src="./assets/images/icons/expand-icon.png" />
                    <img class="collapse" src="./assets/images/icons/collapse-icon.png" />
                  </div>
                  <div class="body">
                    <div class="details-items">
                      <div class="row">
                        <div class="col-md-6">
                          <mat-form-field appearance="outline" class="form-group">
                            <mat-label> First Name</mat-label>
                            <input formControlName="first_name" matInput maxlength="20" />
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'first_name',
                                  'required'
                                )
                              ">Required
                            </mat-error>
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'first_name',
                                  'whiteSpaceValidator'
                                )
                              ">Required
                            </mat-error>
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'first_name',
                                  'pattern'
                                )
                              ">Accept only Characters
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-6">
                          <mat-form-field appearance="outline" class="form-group">
                            <mat-label> Father Name</mat-label>
                            <input formControlName="father_name" matInput maxlength="20" />
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'father_name',
                                  'required'
                                )
                              ">Required
                            </mat-error>
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'father_name',
                                  'whiteSpaceValidator'
                                )
                              ">Required
                            </mat-error>
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'father_name',
                                  'pattern'
                                )
                              ">Accept only Characters
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-6">
                          <mat-form-field appearance="outline" class="form-group">
                            <mat-label> Grandfather Name</mat-label>
                            <input formControlName="grandfather_name" matInput maxlength="20" />
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'grandfather_name',
                                  'required'
                                )
                              ">Required
                            </mat-error>
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'grandfather_name',
                                  'whiteSpaceValidator'
                                )
                              ">Required
                            </mat-error>
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'grandfather_name',
                                  'pattern'
                                )
                              ">Accept only Characters
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-6">
                          <mat-form-field appearance="outline" class="form-group">
                            <mat-label> Family Name</mat-label>
                            <input formControlName="family_name" matInput maxlength="20" />
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'family_name',
                                  'required'
                                )
                              ">Required
                            </mat-error>
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'family_name',
                                  'whiteSpaceValidator'
                                )
                              ">Required
                            </mat-error>
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'family_name',
                                  'pattern'
                                )
                              ">Accept only Characters
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-12">
                          <mat-form-field appearance="outline" class="form-group">
                            <mat-label> Mobile</mat-label>
                            <input formControlName="mobile" minlength="10" maxlength="40" matInput />
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'mobile',
                                  'required'
                                )
                              ">Required
                            </mat-error>
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'mobile',
                                  'minlength'
                                ) ||
                                hasArrayError(
                                  Attendees.controls[i],
                                  'mobile',
                                  'maxlength'
                                )
                              ">mobile must be atleast 10 numbers
                            </mat-error>
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'mobile',
                                  'pattern'
                                )
                              ">Invalid Mobile Number
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-12">
                          <mat-form-field appearance="outline" class="form-group">
                            <mat-label> Email</mat-label>
                            <input formControlName="email" matInput />
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'email',
                                  'required'
                                )
                              ">Required
                            </mat-error>
                            <mat-error *ngIf="
                                hasArrayError(
                                  Attendees.controls[i],
                                  'email',
                                  'pattern'
                                )
                              ">Invalid Format
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="
                          d-flex
                          justify-content-between
                          align-items-center
                        ">
                        <div (click)="removeAttendees(i)" title="remove">
                          <svg height="29" viewBox="0 0 22.999 29" width="22.999" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M443.487,486.638a2.679,2.679,0,0,1-2.651-2.59l-.764-16.441h-.484a.9.9,0,0,1-.885-.906h0v-3.171a2.243,2.243,0,0,1,2.212-2.265h4.864v-.906a2.689,2.689,0,0,1,2.654-2.719h3.538a2.689,2.689,0,0,1,2.654,2.719v.906h4.866a2.24,2.24,0,0,1,2.212,2.266V466.7a.9.9,0,0,1-.885.906h-.483l-.764,16.441a2.679,2.679,0,0,1-2.651,2.59Zm-.883-2.675a.893.893,0,0,0,.883.87h13.432a.893.893,0,0,0,.883-.87l.761-16.355h-16.72Zm-2.13-20.432V465.8h19.458v-2.265a.448.448,0,0,0-.442-.452H440.915a.448.448,0,0,0-.442.449Zm7.076-3.171v.906h5.306v-.906a.9.9,0,0,0-.885-.906h-3.538a.9.9,0,0,0-.883.9Zm6.192,21.749V470.328a.885.885,0,1,1,1.769,0v11.781a.885.885,0,1,1-1.769,0Zm-4.423,0V470.328a.885.885,0,1,1,1.769,0v11.781a.885.885,0,1,1-1.769,0Zm-4.423,0V470.328a.885.885,0,1,1,1.769,0v11.781a.885.885,0,1,1-1.769,0Z"
                              fill="gray" id="delete-icon" transform="translate(-438.703 -457.641)" />
                          </svg>
                        </div>
                        <button (click)="
                            item.valid
                              ? (ActiveCollaped[i] = !ActiveCollaped[i])
                              : null
                          " class="btn-create" color="primary" mat-raised-button>
                          <svg height="14" viewBox="0 0 19 14" width="19" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M155.509,169.385a1.615,1.615,0,0,1,0,2.274L144.8,182.443a1.589,1.589,0,0,1-2.258,0l-5.1-5.134a1.615,1.615,0,0,1,0-2.273,1.588,1.588,0,0,1,2.257,0l3.969,4,9.58-9.648A1.589,1.589,0,0,1,155.509,169.385Z"
                              fill="#fff" id="check-mark" transform="translate(-136.976 -168.914)" />
                          </svg>
                        </button>
                        <!-- <button class="newTrainingbtn" mat-raised-button (click)="addAttendees()" [disabled]="Attendees.controls.length == 25">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14">
                                    <path id="check-mark" d="M155.509,169.385a1.615,1.615,0,0,1,0,2.274L144.8,182.443a1.589,1.589,0,0,1-2.258,0l-5.1-5.134a1.615,1.615,0,0,1,0-2.273,1.588,1.588,0,0,1,2.257,0l3.969,4,9.58-9.648A1.589,1.589,0,0,1,155.509,169.385Z" transform="translate(-136.976 -168.914)" fill="#fff"/>
                                  </svg>
                                </button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <div *ngIf="Ddata.requestedFromExsiestTraining" class="group-price mt-4">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="price-title">Training fees for group</div>
              <div class="price-description">
                The price is fixed to the maximum numbers of attendees
              </div>
            </div>
            <div class="d-flex align-items-center">
              <span class="price-num mr-2">{{ Ddata.feesForrGroup }}</span>
              <span class="price-currency">{{ Ddata.currency_symbols }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="!Ddata.requestedFromExsiestTraining" class="group-price mt-4">
          <div class="d-flex justify-content-center text-center">
            <div>
              <div class="price-title">Training fees for group</div>
              <div class="price-description">
                The price will be determined after reviewing the request
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- buttons -->
      <div class="col-12 p-0">
        <div class="btn-container">
          <button (click)="requestTraining()" [class.loading]="Loading" [disabled]="Loading" class="btn-create w-100"
            color="primary" mat-raised-button>
            <span *ngIf="!Ddata.editMode"> Create Request </span>
            <span *ngIf="Ddata.editMode"> Update Request </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
