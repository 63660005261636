import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CabsRequestService} from 'src/app/features/cabs/service/CabsRequest.service';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
})
export class FileManagerComponent implements OnInit {
  @Output() selectedFiles = new EventEmitter<[]>();
  @Output() deletedFilesList = new EventEmitter<[]>();

  @Input() isRequired;
  @Input() Disapled;
  @Input() isSubmited;
  @Input() cols = 1;
  @Input() label;

  @Input() allowedExt: any[] = [
    'pdf',
    'jpeg',
    'jpg',
    'png',
    'doc',
    'docx',
    'wpd',
    'tex',
    'ppt',
    'pptx',
    'xlsx',
    'xls',
    'zip',
    'rar',
    //new_extensions
    'gif',
    'tiff',
    'bmp',
    'csv'
  ];

  @Input() renderBtn = false;

  // Uploader
  @Input() isUploader = false;
  @Input() uplodaerType = 0;
  uploadedFilesArr: any = [];

  // DnD setting
  @Input() files: any = [];
  @Input() docsEditable = true;
  @Input() maxFiles;
  @Input() managerIndex: any = '0'; // in add numbertab_numberneeded, in details numbertab_d_numberneeded in "''"
  @Input() maxFileSize = 10;

  loading = false;
  selectedfilesCount = 0;
  deletedFiles: any = [];
  validFile = true;
  fileMaxSiZeErorr = false;
  fileMaxNameSiZeErorr = false;

  constructor(
    private service: CabsRequestService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    if (this.files.length && this.isUploader) {
      this.files.forEach(file => {
        this.uploadedFilesArr.push({
          fileName: file.name,
          fileUpload: file.token
        });
      });
      this.selectedFiles.emit(this.uploadedFilesArr);
    }
  }

  fileType(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    if (!this.allowedExt.includes(extension)) {
      return false;
    }
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      case 'doc':
      case 'docx':
      case 'wpd':
      case 'tex':
        return 'doc';
      case 'xlsx':
      case 'xls':
        return 'xls';
      case 'csv':
        return 'xls';
        
      case 'zip':
      case 'rar':
        return 'zip';
      case 'ppt':
      case 'pptx':
        return 'pptx';
      //new_extensions
      case 'gif':
        return 'gif';
      case 'tiff':
        return 'tiff';
      case 'bmp':
        return 'bmp';
      default:
        return false;
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event) {
    this.prepareFilesList(event.target.files);
    event.target.value = '';
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number, id) {
    if (id) {
      this.deletedFiles.push(id);
    }

    const deleted = this.files[index];
    this.files.splice(index, 1);

    if (this.isUploader) {
      const deletedIndx = this.uploadedFilesArr.findIndex(
        (item) => item.fileUpload === deleted.token
      );
      this.uploadedFilesArr.splice(deletedIndx, 1);
      this.selectedFiles.emit(this.uploadedFilesArr);
      this.deletedFilesList.emit(this.deletedFiles);

    } else {
      this.selectedFiles.emit(this.files);
      this.deletedFilesList.emit(this.deletedFiles);
    }
    this.selectedfilesCount = this.files.length;
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    const filesToUpload = [];
    this.validFile = true;
    this.selectedfilesCount = this.files.length + files.length;
    if (this.selectedfilesCount > this.maxFiles) {
      return;
    }
    for (const item of files) {
      if (this.fileType(item.name)) {
        this.validFile = true;
      } else {
        this.validFile = false;
      }

      // Check file and push to array files
      if (item.size / (1024 * 1024) <= this.maxFileSize && this.fileType(item.name)) {
        item.fileType = this.fileType(item.name);
        this.files.push(item);
        filesToUpload.push(item);

        if (this.isUploader) {
          this.uploadFile(filesToUpload);
        } else {
          this.selectedFiles.emit(this.files);
        }

        if (item.size / (1024 * 1024) <= this.maxFileSize) {
          this.fileMaxSiZeErorr = false;
        }
      } else {
        if (item.size / (1024 * 1024) > this.maxFileSize) {
          this.fileMaxSiZeErorr = true;
        }
      }
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadFile(files) {
    this.loading = true;
    this.Disapled = true;
    this.service.upload_file(files, this.uplodaerType).subscribe(
      (response) => {
        this.loading = false;
        this.Disapled = false;
        this.uploadedFilesArr.push(response.data);
        this.selectedFiles.emit(this.uploadedFilesArr);
      },
      (err) => {
        this.loading = false;
        this.Disapled = false;
      }
    );
  }
}
