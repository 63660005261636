import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/service/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccreditationFinancialService {

  refreshIssuedPaymentList = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService) {}

  getDuesToBeIssuedList(filter: any) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/list_dues_to_be_issued`, filter)
      .pipe(catchError(this.handleError));
  }
  getIssuedDuePaymentsList(filter: any) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/issued_due_payments`, filter)
      .pipe(catchError(this.handleError));
  }
  getPaymentToBeApprovedList(filter: any) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/payment_to_be_approved`, filter)
      .pipe(catchError(this.handleError));
  }
  getAllPaymentOptions() {
    return this.apiService
    .get(`${environment.apiUrl}/api/system/cab/get_all_payment_options`)
    .pipe(catchError(this.handleError));
  }

  issueDuePayment(data: any, endPoint) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/` + endPoint, data)
      .pipe(catchError(this.handleError));
  }
  updateDuePayment(data: any) {
    // const formData = new FormData();
    // formData.append('notes', data.notes);
    // formData.append('items', data.items);
    // if (files && files.length) {
    //   files.forEach(file => {
    //     formData.append('attached_file[]', file, file.name);
    //   });
    // }
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/update_payment`, data)
      .pipe(catchError(this.handleError));
  }

  getCabPaymentItems(data) {
    return this.apiService
    .post(`${environment.apiUrl}/api/system/cab/get_cab_payment_items`, data)
    .pipe(catchError(this.handleError));
  }
  getPaymentDetailsByToken(token) {
    return this.apiService
    .post(`${environment.apiUrl}/api/system/cab/payment_details_by_token`, token)
    .pipe(catchError(this.handleError));
  }
  reviewPayment(data) {
    return this.apiService
    .post(`${environment.apiUrl}/api/system/cab/fm_approve_payment`, data)
    .pipe(catchError(this.handleError));
  }

  // Financial Manager payment
  confirmOfflinePayment(data) {
    return this.apiService
    .post(`${environment.apiUrl}/api/system/cab/fm_submit_payment_then_approve`, data)
    .pipe(catchError(this.handleError));
  }
  onlinePayment(data) {
    return this.apiService
    .post(`${environment.apiUrl}/api/system/OnlinePayment`, data)
    .pipe(catchError(this.handleError));
  }
  uploadBankTransferProofFinancialManager(data) {
    const formData = new FormData();
    // "3Pcz67RsGZfbA6Qr9Brf0uCkxrQl5DtctsOfU7m1"
    formData.append('payment_token', data.payment_token);
    formData.append('payment_option', data.payment_option);
    data.attached_file.forEach((element) => {
      if (element.type) {
        formData.append('attached_file', element, element.name);
      }
    });
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/cab/fm_submit_payment_then_approve`,
        formData
      )
      .pipe(catchError(this.handleError));
  }

  // CAB Admin payment
  uploadBankTransferProofCab(data) {
    const formData = new FormData();
    formData.append('request_token', data.request_token);
    formData.append('payment_option', data.payment_option);
    data.attached_file.forEach((element) => {
      if (element.type) {
        formData.append('attached_file', element, element.name);
      }
    });
    return this.apiService
      .post( // cab_admin_pay_document_review_payment
        `${environment.apiUrl}/api/system/cab/${data.endPoint}`,
        formData
      )
      .pipe(catchError(this.handleError));
  }

  getPaymentOptions(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/get_payment_options `, model)
      .pipe(catchError(this.handleError));
  }
  getCabPaymentOptions(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/get_cab_payment_options `, model)
      .pipe(catchError(this.handleError));
  }


  exportPaymentAsExcel(model) {
    return this.apiService
      .getDownload(
        `${environment.apiUrl}/api/system/cab/TeamExportExcel`,
        model
      )
      .pipe(catchError(this.handleError));
  }
  exportPaymentAsPdf(model) {
    return this.apiService
      .getDownload(
        `${environment.apiUrl}/api/system/cab/TeamExportPdf`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  removePaymentAttachments(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/remove_payment_attachments`, model)
      .pipe(catchError(this.handleError));
  }

  handleError(error) {
    return throwError(error);
  }
}
